import * as React from 'react';
import {FC, useEffect, useMemo, useState} from 'react';
import _ from 'lodash-es'
import * as backend from '../Backend';
import { Account, User } from '../Backend/Models/User';
import { formatDateTime } from '../Util';
import { Toast } from './Index';
import { DataTable } from '../Components/DataTable';
import { BidderRegistration } from '../Backend/Models/BidderRegistration';
import { useForm } from 'react-hook-form';
import ConfirmationModal from '../Components/ConfirmationModal';
import FormModal from '../Components/FormModal';

const ACTIONS: any = {
  'create': "created",
  'update': "updated"
}

const BIDDER_REGISTRATION_SEND_INSTRUCTIONS = 'BIDDER_REGISTRATION_SEND_INSTRUCTIONS'

const STATUSES: any = {
  'pending': 'Pending',
  'on_hold': 'On Hold',
  'rejected': 'Rejected',
  'accepted': 'Accepted',
}

const QUESTIONNAIRE_KEYS: any = {
  how: 'How did you hear about FW / Referrals',
  job: null,
  linkedin: null,
  instagram: null,
  twitter: null,
}

const getAuditedValue = (key: string, value: any) => {
  if (key === 'status') {
    return STATUSES[value]
  } else if (key === 'lastLoginAt') {
    return formatDateTime(value)
  }

  return value
}

const UserPage: FC<{ toast: (toast: Toast) => void; userId: string }> = ({
  toast,
  userId,
}) => {
  let [user, setUser] = useState<User | null>(null);
  const [eventsInstructions, setEventsInstructions] = useState<any[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [labelOpen, setLabelOpen] = useState(false);
  const [railsUser, setRailsUser] = useState<any>(null);
  const [plaidAccounts, setPlaidAccounts] = useState<any[]>([]);
  const [financialVerification, setFinancialVerification] = useState<any>(null);
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false);
  const [showNewAccount, setShowNewAccount] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm();
  const [bidderRegistration, setBidderRegistration] =
    useState<BidderRegistration | null>(null);
  const [questionnaire, setQuestionnaire] = useState<any | null>(null);
  const [questionnaireVersions, setQuestionnaireVersions] = useState<string[]>(
    []
  );
  const [showCreateQuestionnaire, setShowCreateQuestionnaire] =
    useState<boolean>(false);
  const [identityVerifications, setIdentityVerifications] = useState<
    any[] | null
  >(null);
  const [canEditBidderRegistration, setCanEditBidderRegistration] =
    useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const [editUserInfo, setEditUserInfo] = useState(false);
  const [userInfoUpdateRequest, setUserInfoUpdateRequest] = useState<{
    firstName?: string;
    lastName?: string;
    email?: string;
    profile_attributes?: {
      phoneNumber?: string;
    }
  }>({});

  const loadEventsSendInstructions = () => {
    backend.user.userEventsByType(userId, BIDDER_REGISTRATION_SEND_INSTRUCTIONS).then((response: any) => {
      setEventsInstructions(response)
    })
  }

  const onClickLabel = () => {
    setLabelOpen(!labelOpen)
  }

  const onHandleLabel = (label: string) => {
    backend.user.updateRailsUser(user!, { user: { status: label } }).then(() => {
      toast({ type: 'good', message: 'User labeled' });
      loadUser();
      setLabelOpen(false)
    })
  }

  function accept() {
    backend.user
      .accept(user!)
      .then(() => loadUser())
      .then(() => setShowAcceptConfirmation(false))
      .then(() => toast({ type: 'good', message: 'User has been accepted!' }))
      .catch((error) => toast({ type: 'bad', message: error.message }));
  }

  async function resetBidder() {
    await backend.user.resetBidder(user!);
    loadUser();
    toast({ type: 'good', message: 'Bidder reset' });
  }

  async function triggerApprovedEmail() {
    await backend.user.triggerApprovedEmail(user!);
    toast({ type: 'good', message: 'Approved email triggered' });
  }

  async function updateUserOnApp() {
    setDisabled(true);
    await backend.user.updateUserOnApp(user!);
    loadUser();
    toast({ type: 'good', message: 'Updated user on App' });
    setTimeout(() => {
      setDisabled(false);
    }, 3000);
  }

  async function getPlaidAccounts() {
    const accounts = await backend.user.getPlaidAccounts(userId);
    setPlaidAccounts(accounts);
  }

  const totalAvailableAccounts = plaidAccounts
    .filter((account) => account.available)
    .reduce((acc, account) => acc + account.available, 0);

  function goToVerifications() {
    window.open(
      `${process.env.REACT_APP_VERIFICATIONS_URL}/auth/sign-in?token=${accessToken}`
    );
  }

  async function toggleAdmin() {
    backend.user
      .update(user!, { user: { admin: !user!.admin } })
      .then(() => loadUser())
      .then(() => toast({ type: 'good', message: 'Admin state changed' }))
      .catch((error) => toast({ type: 'bad', message: error.message }));
  }

  async function unlockUser() {
    backend.user
      .update(user!, { user: { locked_at: null, login_attempts: 0 } })
      .then(() => loadUser())
      .then(() => toast({ type: 'good', message: 'User unlocked' }))
      .catch((error) => toast({ type: 'bad', message: error.message }));
  }

  async function toggleDisable() {
    backend.user
      .update(user!, { disabled: !user!.disabled })
      .then((u) => {
        setUser(u);
        return u;
      })
      .then((u) =>
        toast({
          type: 'good',
          message: `${u.disabled ? 'Disabled' : 'Enabled'} ${u.firstName} ${
            u.lastName
          }`,
        })
      )
      .catch((error) => toast({ type: 'bad', message: error.message }));
  }

  async function createAccount(data: any) {
    backend.user
      .createAccount(user!.id, {
        name: data.name,
        type: data.type,
      })
      .then(() => reset())
      .then(() => setShowNewAccount(false))
      .then(() => loadUser())
      .then(() => toast({ type: 'good', message: 'Created account.' }));
  }

  function makePrimary(account: Account) {
    backend.user
      .promoteAccount(user!.id, account.id)
      .then(loadUser)
      .then(() => toast({ type: 'good', message: 'New primary account.' }));
  }

  function loadUser() {
    backend.user.get(userId).then(setUser);
    loadBidderRegistration();
    loadQuestionnaire();
    getPlaidAccounts();
    loadEventsSendInstructions();

    backend.user.getRailsUser(userId).then((response: any) => {
      setRailsUser(response)
      setAccessToken(response?.accessToken);
      setFinancialVerification(response?.bidderRegistration?.financialVerification)
    });
  }

  function changeBidderRegistrationState(newState: string) {
    backend.user
      .changeBidderRegistrationState(user!.id, newState)
      .then(setBidderRegistration);
  }

  function questionnaireButtons(): any {
    if (!questionnaire) return;

    if (questionnaire.version === 'NO_QUESTIONNAIRE') {
      return (
        <div className="buttons is-right">
          <button
            className="button is-primary"
            onClick={() => setShowCreateQuestionnaire(true)}
          >
            Create Questionnaire
          </button>
        </div>
      );
    }
  }

  function bidderRegistrationButtons(): any {
    if (!bidderRegistration) return;

    const state = bidderRegistration.currentState;

    if (state === 'NOT_STARTED') {
      return (
        <div className="buttons is-right">
          <button
            className="button is-primary"
            onClick={() => changeBidderRegistrationState('APPROVED')}
          >
            APPROVE
          </button>
        </div>
      );
    }

    if (state === 'APPROVED') {
      return (
        <div className="buttons is-right">
          <button
            className="button is-danger"
            onClick={() => changeBidderRegistrationState('REJECTED')}
          >
            REJECT
          </button>
        </div>
      );
    }

    if (state === 'REJECTED') {
      return (
        <div className="buttons is-right">
          <button
            className="button is-primary"
            onClick={() => changeBidderRegistrationState('APPROVED')}
          >
            APPROVE
          </button>
        </div>
      );
    }

    if (state === 'ADMIN_PENDING' || state === 'IN_PROGRESS') {
      return (
        <div className="buttons is-right">
          <button
            className="button is-danger"
            onClick={() => changeBidderRegistrationState('REJECTED')}
          >
            REJECT
          </button>

          <button
            className="button is-primary"
            onClick={() => changeBidderRegistrationState('APPROVED')}
          >
            APPROVE
          </button>
        </div>
      );
    }
  }

  function toggleBidding(account: Account) {
    backend.account
      .updateBidding(account.id)({ can_bid: !account.canBid })
      .then(loadUser);
  }

  function loadBidderRegistration() {
    backend.user.bidderRegistration(userId).then((br) => {
      setCanEditBidderRegistration(
        br.currentState === 'IN_PROGRESS' || br.currentState === 'NOT_STARTED'
      );
      setBidderRegistration(br);
    });

    backend.user.identityVerifications(userId).then(setIdentityVerifications);
  }

  function loadQuestionnaire() {
    backend.user.questionnaireVersions(userId).then(setQuestionnaireVersions);

    backend.user.questionnaire(userId).then((q) => setQuestionnaire(q));
  }

  function createQuestionnaire(version: string) {
    backend.user.createQuestionnaire(userId, { version }).then(() => {
      setShowCreateQuestionnaire(false);
      loadQuestionnaire();
    });
  }

  function createIdentityVerificationOverride() {
    backend.user.createIdentityVerification(userId).then(() => loadUser());
  }

  function createConditionsOfSaleOverride() {
    backend.user.createConditionsOfSale(userId).then(() => loadUser());
  }

  function toggleBiddingLimit() {
    backend.user.toggleBiddingLimit(userId).then(() => loadUser());
  }

  function createExtraFeesOverride() {
    backend.user.createExtraFees(userId).then(() => loadUser());
  }

  function createShippingAddressOverride() {
    backend.user.createShippingAddress(userId).then(() => loadUser());
  }

  function syncPlaidAccounts() {
    backend.user.syncPlaidAccounts(userId).then(() => {
      toast({ type: 'good', message: 'Plaid accounts synced' });
      getPlaidAccounts();
    });
  }

  function approveFinancialVerification() {
    backend.user.financialVerificationUpdate(userId, { financial_verification: { status: 'DONE' } }).then(() => {
      toast({ type: 'good', message: 'Financial verification approved' });
      loadUser();
    })
  }

  function renderNetwork(url: string, username: string) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        @{username}
      </a>
    );
  }
  function renderAnswer(question: any, key: string) {
    /**
     * CHANGED BY LUIYIT
     * Now the value key is just an string
     */
    if (!question.answer && !question.value) {
      return '';
    }

    switch (question.meta.type) {
      case 'MultiShortText':
        return Object.entries(question.answer).map(
          ([key, val]) => `${key}: ${val} `
        );
      case 'MultiSelect':
        return Array.isArray(question.answer)
          ? question.answer.join(', ')
          : question.answer;
      case 'ShortText':
        switch (key) {
          case 'twitter':
            return renderNetwork(
              `https://twitter.com/${question.value}`,
              question.value
            );

          case 'linkedin':
            return renderNetwork(
              `https://www.instagram.com/${question.value}`,
              question.value
            );

          case 'instagram':
            return renderNetwork(
              `https://www.linkedin.com/in/${question.value}`,
              question.value
            );

          default:
            return question.answer || question.value;
        }
      default:
        return question.answer || question.value;
    }
  }

  async function updateUserInfo(updateInfo: any) {
    try {
      setUser(await backend.user.update(user!, updateInfo));
      toast({ type: 'good', message: 'User information successfully updated' });
    } catch (err) {
      toast({ type: 'bad', message: err.message });
    }
    setEditUserInfo(false);
  }

  useEffect(() => {
    loadUser();
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  const bidderRegistrationStates = useMemo(() => {
    if (eventsInstructions.length === 0) {
      return bidderRegistration?.states || []
    }

    return [...bidderRegistration?.states || [], ...eventsInstructions.map(e => (
      {
        ...e,
        name: 'Requested instructions via email'
      }
    ))].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
  }, [bidderRegistration, eventsInstructions])

  const onGoBack = () => {
    window.history.back()
  }

  return (
    <>
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><span className="as_link as_link_padding" onClick={onGoBack}>Users</span></li>
          <li className="is-active"><span className="as_link_padding">{userId}</span></li>
        </ul>
      </nav>
      {user ? (
        <>
          <ConfirmationModal
            bodyText={`Would you like to accept ${user.firstName} ${user.lastName}? There's no undo.`}
                confirmText="Accept"
                visible={showAcceptConfirmation}
                onConfirm={() => accept()}
                onCancel={() => setShowAcceptConfirmation(false)}
            />
            <FormModal
                form={[
                  {
                    label: 'Version',
                    inputType: 'select',
                    selectOptions: questionnaireVersions,
                  },
                ]}
                confirmText="Create"
                onConfirm={(version) => createQuestionnaire(version)}
                onCancel={() => setShowCreateQuestionnaire(false)}
                visible={showCreateQuestionnaire}
            />
            <div className={`modal ${showNewAccount ? 'is-active' : ''}`}>
              <div
                  className="modal-background"
                  onClick={() => setShowNewAccount(false)}
              />
              <div className="modal-content">
                <div className="box">
                  <form onSubmit={handleSubmit(createAccount)}>
                    <div className="field">
                      <label htmlFor="name" className="label">
                        Name
                      </label>
                      <div className="control">
                        <input
                            className="input"
                            name="name"
                            ref={register({required: true})}
                        />
                        {errors.name && (
                            <p className="help is-danger">Name required</p>
                        )}
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="type" className="label">
                        Type
                      </label>
                      <div className="control">
                        <select name="type" ref={register}>
                          <option value="INDIVIDUAL">Individual</option>
                          <option value="COMPANY">Company</option>
                        </select>
                        {errors.type && (
                            <p className="help is-danger">Type required</p>
                        )}
                      </div>
                    </div>

                    <button className="button is-success" type="submit">
                      Create
                    </button>
                  </form>
                </div>
              </div>
              <button
                  className="modal-close is-large"
                  onClick={() => setShowNewAccount(false)}
              />
            </div>
            <div className="columns is-vcentered">
              <div className="column">
                <h1 className="title">
                  {user.firstName} {user.lastName}
                </h1>
              </div>
              <div className="column">
                <div className="buttons is-pulled-right">
                  {user.profile.acceptedAt == null ? (
                      <button
                          className="button is-primary is-light"
                          onClick={() => setShowAcceptConfirmation(true)}
                      >
                        Accept
                      </button>
                  ) : null}
                  <button
                      className="button is-warning is-light"
                      onClick={() => toggleAdmin()}
                  >
                    Toggle Admin
                  </button>
                  {railsUser?.lockedAt && (
                    <button
                      className="button is-danger is-light"
                      onClick={() => unlockUser()}
                    >
                      Unlock
                    </button>
                  )}
                  <button
                      className={'button is-danger is-light'}
                      onClick={() => toggleDisable()}
                  >
                    {user.disabled ? 'Enable' : 'Disable'}
                  </button>
                  {editUserInfo ? (
                      <>
                        <button
                            className="button is-danger is-light"
                            onClick={() => updateUserInfo(userInfoUpdateRequest)}
                        >
                          Apply changes
                        </button>
                        <button
                            className="button"
                            onClick={() => setEditUserInfo(false)}
                        >
                          Cancel
                        </button>
                      </>
                  ) : (
                      <button
                          className="button is-warning is-light"
                          onClick={() => setEditUserInfo(true)}
                      >
                        Edit personal information
                      </button>
                  )}
                  <button
                      className="button is-primary is-light"
                      disabled={disabled}
                      onClick={updateUserOnApp}
                  >
                    Update user on App
                  </button>
                  <div style={{position: 'relative'}}>
                    <button
                        className="button is-danger"
                        disabled={!!user.profile?.acceptedAt}
                        onClick={onClickLabel}
                    >
                      Label
                    </button>

                    {labelOpen && (
                        <div style={{
                          position: "absolute",
                          background: "white",
                          border: "1px solid #b9b9b9",
                          padding: "16px",
                          width: "160px",
                        }}>
                          <p style={{cursor: 'pointer'}} onClick={() => onHandleLabel('on_hold')}>On Hold</p>
                          <p style={{cursor: 'pointer'}} onClick={() => onHandleLabel('pending')}>Pending</p>
                          <p style={{cursor: 'pointer'}} onClick={() => onHandleLabel('rejected')}>Rejected</p>
                        </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="columns">
                <div className="column">
                  <strong>First Name</strong>
                </div>
                <div className="column">
                  {editUserInfo ? (
                      <input
                          className="input"
                          type="text"
                          placeholder={user.firstName}
                          onChange={(e) =>
                              setUserInfoUpdateRequest({
                                ...userInfoUpdateRequest,
                                firstName: e.target.value || undefined,
                              })
                          }
                      />
                  ) : (
                      user.firstName
                  )}
                </div>
                <div className="column">
                  <strong>Email</strong>
                </div>
                <div className="column">
                  {editUserInfo ? (
                      <input
                          className="input"
                          type="email"
                          placeholder={user.email}
                          onChange={(e) =>
                              setUserInfoUpdateRequest({
                                ...userInfoUpdateRequest,
                                email: e.target.value || undefined,
                              })
                          }
                      />
                  ) : (
                      user.email
                  )}
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <strong>Last Name</strong>
                </div>
                <div className="column">
                  {editUserInfo ? (
                      <input
                          className="input"
                          type="text"
                          placeholder={user.lastName}
                          onChange={(e) =>
                              setUserInfoUpdateRequest({
                                ...userInfoUpdateRequest,
                                lastName: e.target.value || undefined,
                              })
                          }
                      />
                  ) : (
                      user.lastName
                  )}
                </div>
                <div className="column">
                  <strong>Firebase ID</strong>
                </div>
                <div className="column">{user.firebaseId}</div>
              </div>
              <div className="columns">
                <div className="column">
                  <strong>Status</strong>
                </div>
                <div className="column">{railsUser?.status}</div>
                <div className="column"><strong>Locked At</strong></div>
                <div className="column">{railsUser?.lockedAt}</div>
              </div>
              <div className="columns">
                <div className="column">
                  <strong>Admin?</strong>
                </div>
                <div className="column">{user.admin ? 'Yes' : 'No'}</div>
                <div className="column">
                  <strong>Disabled?</strong>
                </div>
                <div className="column">{user.disabled ? 'Yes' : 'No'}</div>
              </div>
              <div className="columns">
                <div className="column">
                  <strong>Created At</strong>
                </div>
                <div className="column">{formatDateTime(user.createdAt)}</div>
                <div className="column">
                  <strong>Modified At</strong>
                </div>
                <div className="column">{formatDateTime(user.createdAt)}</div>
              </div>
              <div className="columns">
                <div className="column">
                  <strong>VIP</strong>
                </div>
                <div className="column">{user.vip ? 'Yes' : 'No'}</div>
                <div className="column"></div>
                <div className="column"></div>
              </div>
            </div>
            <div className="box">
              <h2 className="subtitle">Profile</h2>
              {user.profile ? (
                <>
                    <div className="columns">
                      <div className="column">
                        <strong>Phone #</strong>
                      </div>
                      <div className="column">
                        {editUserInfo ? (
                            <input
                                className="input"
                                type="tel"
                                placeholder={user.profile.phoneNumber}
                                onChange={(e) =>
                                    setUserInfoUpdateRequest({
                                      ...userInfoUpdateRequest,
                                      profile_attributes: {
                                        phoneNumber: e.target.value || undefined,
                                      }
                                    })
                                }
                            />
                        ) : (
                            user.profile.phoneNumber
                        )}
                      </div>
                      <div className="column">
                        <strong>Address</strong>
                      </div>
                      <div className="column">{user.profile.address}</div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <strong>Referral</strong>
                      </div>
                      <div className="column">{user.referralCode?.code || '-'}</div>
                      <div className="column">
                        <strong>Accepted At</strong>
                      </div>
                      <div className="column">
                        {formatDateTime(user.profile.acceptedAt)}
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <strong>Road Rules Accepted At</strong>
                      </div>
                      <div className="column">
                        {formatDateTime(user.profile.roadRulesAcceptedAt)}
                      </div>
                      <div className="column"/>
                      <div className="column"/>
                    </div>
                  </>
              ) : (
                  <div>No profile</div>
              )}
            </div>
            {user ? (
                <div className="box">
                  <h2 className="subtitle">Accounts</h2>
                  {user.accounts.length ? (
                      <>
                        <table className="table is-fullwidth">
                          <thead>
                          <tr>
                            <th>
                              <strong>Name</strong>
                            </th>
                            <th>
                              <strong>Type</strong>
                            </th>
                            <th>
                              <strong>Created At</strong>
                            </th>
                            <th>
                              <strong>Auction Mobility ID</strong>
                            </th>
                            <th>
                              <strong>Auction Mobility Email</strong>
                            </th>
                            <th>
                              <strong>Primary</strong>
                            </th>
                            <th>
                              <strong>Can Bid?</strong>
                            </th>
                            <th/>
                          </tr>
                          </thead>
                          <tbody>
                          {user.accounts.map((a) => (
                              <tr key={a.id}>
                                <td>{a.name}</td>
                                <td>{a.type}</td>
                                <td>{formatDateTime(a.createdAt)}</td>
                                <td>{a.auctionMobilityId}</td>
                                <td>{a.auctionMobilityEmail}</td>
                                <td>
                                  {a.primary ? (
                                      '✓'
                                  ) : (
                                      <span className="as_link" onClick={() => makePrimary(a)}>Promote</span>
                                  )}
                                </td>
                                <td>{a.canBid ? '✓' : ''}</td>
                                <td>
                                  <span className="as_link" onClick={() => toggleBidding(a)}>
                                    {a.canBid ? 'Disable Bidding' : 'Enable Bidding'}
                                  </span>
                                </td>
                              </tr>
                          ))}
                          </tbody>
                        </table>
                      </>
                  ) : (
                      <div>No Accounts</div>
                  )}
                  <div className="buttons is-right">
                    <button
                        className="button is-warning is-light"
                        onClick={() => setShowNewAccount(true)}
                    >
                      New Account
                    </button>
                    <button className="button is-primary is-light" disabled>
                      Associate Account
                    </button>
                  </div>
                </div>
            ) : null}
            <div className="box">
              <div className="columns">
                <div className="column">
                  <div className="subtitle">Questionnaire</div>
                </div>
                <div className="column">{questionnaireButtons()}</div>
              </div>
              {questionnaire && questionnaire.version !== 'NO_QUESTIONNAIRE' ? (
                  [
                    <div className="columns" key={'completedAt'}>
                      <div className="column">
                        <strong>Completed At</strong>
                      </div>
                      <div className="column">
                        {formatDateTime(questionnaire.completedAt)}
                      </div>
                    </div>,
                  ].concat(
                      Object.entries(questionnaire.data).map(
                          ([key, value]: any[]) => (
                              <div key={key} className="columns">
                                <div className="column">
                                  <strong>{QUESTIONNAIRE_KEYS[key] || _.capitalize(key)}</strong>
                                </div>
                                <div className="column">{renderAnswer(value, key)}</div>
                              </div>
                          )
                      )
                  )
              ) : (
                  <div>No Questionnaire</div>
              )}
            </div>

            {user ? (
                <div className="box">
                  <div className="columns">
                    <div className="column">
                      <h2 className="subtitle">Bidder Registration </h2>
                    </div>
                    <div className="column">
                      {bidderRegistrationButtons()}

                      <div className="buttons is-right">
                        <button
                            className="button is-primary"
                            onClick={triggerApprovedEmail}
                        >
                          Trigger approved email
                        </button>
                        <button className="button is-danger" onClick={resetBidder}>
                          RESET BIDDER
                        </button>
                        <button
                            className="button is-primary"
                            onClick={goToVerifications}
                        >
                          Go to verifications
                        </button>
                      </div>
                    </div>
                  </div>
                  {bidderRegistration && identityVerifications ? (
                      <>
                        <div className="columns">
                          <div className="column">
                            <strong>State</strong>
                          </div>
                          <div className="column">
                            {bidderRegistration.currentState}
                          </div>
                        </div>
                        <div className="box">
                          <h3 className="subtitle is-6">States</h3>
                          <table className="table is-fullwidth">
                            <thead>
                            <tr>
                              <th>
                                <strong>Created At</strong>
                              </th>
                              <th>
                                <strong>Name</strong>
                              </th>
                              <th>
                                <strong>Created By</strong>
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            {bidderRegistrationStates.map((a) => (
                                <tr key={a.id}>
                                  <td>{formatDateTime(a.createdAt)}</td>
                                  <td>{a.name}</td>
                                  <td>{a.createdBy}</td>
                                </tr>
                            ))}
                            </tbody>
                          </table>
                        </div>
                        <hr/>
                        <h3 className="subtitle is-6">
                          <strong>User Steps</strong>
                        </h3>
                        <div className="box">
                          <div className="columns">
                            <div className="column">
                              <h3 className="subtitle is-6">Identity Verification</h3>
                            </div>
                            <div className="column">
                              {canEditBidderRegistration ? (
                                  <div className="buttons is-right">
                                    <button
                                        className="button is-warning is-light"
                                        onClick={() =>
                                            createIdentityVerificationOverride()
                                        }
                                    >
                                      MANUALLY APPROVE
                                    </button>
                                  </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>State</strong>
                            </div>
                            <div className="column">
                              {identityVerifications[0]?.state}
                            </div>
                            <div className="column"/>
                            <div className="column"/>
                          </div>
                          <table className="table is-fullwidth">
                            <thead>
                            <tr>
                              <th>
                                <strong>Created At</strong>
                              </th>
                              <th>
                                <strong>State</strong>
                              </th>
                              <th>
                                <strong>Type</strong>
                              </th>
                              <th>
                                <strong>Automated?</strong>
                              </th>
                              <th>
                                <strong>Document State</strong>
                              </th>
                              <th>
                                <strong>Identity State</strong>
                              </th>
                              <th>
                                <strong>World Check Hit?</strong>
                              </th>
                              <th/>
                              <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {identityVerifications.map((iv) => (
                                <tr key={iv.id}>
                                  <td>{formatDateTime(iv.createdAt)}</td>
                                  <td>{iv.state}</td>
                                  <td>{iv.type}</td>
                                  <td>{iv.automated ? '✓' : ''}</td>
                                  <td>
                                    {iv.qualIdIdentityVerification?.worldCheckHit
                                        ? '✓'
                                        : ''}
                                  </td>
                                  <td>
                                    <span
                                        className="as_link"
                                        onClick={() =>
                                            backend.user
                                                .approveIdentityVerification(userId, iv.id)
                                                .then(() => loadBidderRegistration())
                                        }
                                    >
                                      Approve
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                        className="as_link"
                                        onClick={() =>
                                            backend.user
                                                .rejectIdentityVerification(userId, iv.id)
                                                .then(() => loadBidderRegistration())
                                        }
                                    >
                                      Reject
                                    </span>
                                  </td>
                                </tr>
                            ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="box">
                          <div className="columns">
                            <div className="column">
                              <h3 className="subtitle is-6">Conditions of Sale</h3>
                            </div>
                            <div className="column">
                              {canEditBidderRegistration ? (
                                  <div className="buttons is-right">
                                    <button
                                        className="button is-warning is-light"
                                        onClick={() => createConditionsOfSaleOverride()}
                                    >
                                      MANUALLY APPROVE
                                    </button>
                                  </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>State</strong>
                            </div>
                            <div className="column">
                              {bidderRegistration.conditionsOfSaleAcceptedAt ? 'APPROVED' : 'PENDING'}
                            </div>
                            <div className="column">
                              <strong>Accepted At</strong>
                            </div>
                            <div className="column">
                              {formatDateTime(
                                  bidderRegistration.conditionsOfSaleAcceptedAt
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="box">
                          <div className="columns">
                            <div className="column">
                              <h3 className="subtitle is-6">Extra Fees</h3>
                            </div>
                            <div className="column">
                              {canEditBidderRegistration ? (
                                  <div className="buttons is-right">
                                    <button
                                        className="button is-warning is-light"
                                        onClick={() => createExtraFeesOverride()}
                                    >
                                      MANUALLY APPROVE
                                    </button>
                                  </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>State</strong>
                            </div>
                            <div className="column">
                              {bidderRegistration.extraFeesAcceptedAt ? 'APPROVED' : 'PENDING'}
                            </div>
                            <div className="column">
                              <strong>Accepted At</strong>
                            </div>
                            <div className="column">
                              {formatDateTime(
                                  bidderRegistration.extraFeesAcceptedAt
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="box">
                          <div className="columns">
                            <div className="column">
                              <h3 className="subtitle is-6">
                                Bidding Limit (
                                <strong>
                                  {bidderRegistration.biddingLimitEnabled
                                      ? 'ACTIVE'
                                      : 'WAIVED'}
                                </strong>
                                )
                              </h3>
                            </div>
                            <div className="column">
                              {canEditBidderRegistration ? (
                                  <div className="buttons is-right">
                                    <button
                                        className="button is-warning is-light"
                                        onClick={() => toggleBiddingLimit()}
                                    >
                                      {bidderRegistration.biddingLimitEnabled
                                          ? 'WAIVE'
                                          : 'REQUIRE'}
                                    </button>
                                  </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>State</strong>
                            </div>
                            <div className="column">
                            </div>
                            <div className="column">
                              <strong>Amount</strong>
                            </div>
                            <div className="column">
                              {bidderRegistration.biddingLimit}
                            </div>
                          </div>
                        </div>

                        <div className="box">
                          <div className="columns">
                            <div className="column">
                              <h3 className="subtitle is-6">Shipping Address</h3>
                            </div>
                            <div className="column">
                              {canEditBidderRegistration ? (
                                  <div className="buttons is-right">
                                    <button
                                        className="button is-warning is-light"
                                        onClick={() => createShippingAddressOverride()}
                                    >
                                      MANUALLY APPROVE
                                    </button>
                                  </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>State</strong>
                            </div>
                            <div className="column">
                            </div>
                            <div className="column">
                              <strong>Coordination Type</strong>
                            </div>
                            <div className="column">
                              {
                                bidderRegistration.shippingAddressCoordinationType
                              }
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>Full Name</strong>
                            </div>
                            <div className="column">
                              {bidderRegistration.shippingAddressFullName}
                            </div>
                            <div className="column"/>
                            <div className="column"/>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>Line 1</strong>
                            </div>
                            <div className="column">
                              {bidderRegistration.shippingAddressLineOne}
                            </div>
                            <div className="column"/>
                            <div className="column"/>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>Line 2</strong>
                            </div>
                            <div className="column">
                              {bidderRegistration.shippingAddressLineTwo}
                            </div>
                            <div className="column"/>
                            <div className="column"/>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>Line 3</strong>
                            </div>
                            <div className="column">
                              {bidderRegistration.shippingAddressLineThree}
                            </div>
                            <div className="column"/>
                            <div className="column"/>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>City</strong>
                            </div>
                            <div className="column">
                              {bidderRegistration.shippingAddressCity}
                            </div>
                            <div className="column"/>
                            <div className="column"/>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>State/Province/Region</strong>
                            </div>
                            <div className="column">
                              {
                                bidderRegistration.shippingAddressStateProvinceRegion
                              }
                            </div>
                            <div className="column"/>
                            <div className="column"/>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>Postal Code</strong>
                            </div>
                            <div className="column">
                              {bidderRegistration.shippingAddressPostalCode}
                            </div>
                            <div className="column"/>
                            <div className="column"/>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>Country</strong>
                            </div>
                            <div className="column">
                              {bidderRegistration.shippingAddressCountry}
                            </div>
                            <div className="column"/>
                            <div className="column"/>
                          </div>
                        </div>
                        <div className="box">
                          <div className="columns">
                            <div className="column">
                              <h3 className="subtitle is-6">
                                Financial Verification Accounts
                              </h3>
                              Status: <strong>{financialVerification?.status || "-"}</strong>
                            </div>
                            <div className="column">
                              <div className="buttons is-right">
                                Total Available amount:
                                <strong>${totalAvailableAccounts}</strong>
                                <button
                                    className="button is-warning is-light"
                                    onClick={() => syncPlaidAccounts()}
                                >
                                  Sync Accounts
                                </button>
                                <button
                                    className="button is-warning is-light"
                                    onClick={() => approveFinancialVerification()}
                                >
                                  Manually Approve
                                </button>
                              </div>
                            </div>
                          </div>
                          <table className="table is-fullwidth">
                            <thead>
                            <tr>
                              <th>
                                <strong>Account ID</strong>
                              </th>
                              <th>
                                <strong>Available</strong>
                              </th>
                              <th>
                                <strong>Current</strong>
                              </th>
                              <th>
                                <strong>Currency</strong>
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            {plaidAccounts.map((account) => (
                                <>
                                  <tr key={account.id}>
                                    <td>{account.id}</td>
                                    <td>
                                      <strong>
                                        {account.available
                                            ? `$${account.available}`
                                            : '-'}
                                      </strong>
                                    </td>
                                    <td>${account.current}</td>
                                    <td>{account.isoCurrencyCode}</td>
                                  </tr>
                                  {account.owners.map((owner: any, index: number) => (
                                      <tr key={`owner-${index}`}>
                                        <td colSpan={4}>
                                          <table className="table is-fullwidth">
                                            <thead>
                                            <tr>
                                              <th>
                                                <strong>Names</strong>
                                              </th>
                                              <th>
                                                <strong>Emails</strong>
                                              </th>
                                              <th>
                                                <strong>Phone numbers</strong>
                                              </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                              <td>
                                                {owner.names.map((name: any) => (
                                                    <div key={`name-${name}`}>
                                                      {name}
                                                    </div>
                                                ))}
                                              </td>
                                              <td>
                                                {owner.emails.map((email: any) => (
                                                    <div key={`email-${email.data}`}>
                                                      {email.data}
                                                    </div>
                                                ))}
                                              </td>
                                              <td>
                                                {owner.phoneNumbers.map(
                                                    (phoneNumber: any) => (
                                                        <div
                                                            key={`phone-${phoneNumber.data}`}
                                                        >
                                                          {phoneNumber.data}
                                                        </div>
                                                    )
                                                )}
                                              </td>
                                            </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                  ))}
                                </>
                            ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                  ) : (
                      <div>No Bidder Registration</div>
                  )}
                </div>
            ) : null}
            <div className="box">
              <h2 className="subtitle">Sell Requests</h2>
              <DataTable
                  columns={[
                    ['artistName', 'Artist Name'],
                    ['medium', 'Medium'],
                    ['year', 'Year'],
                    ['other', 'Other'],
                    ['relevantLink', 'Relevant Link'],
                    ['imageUrl', 'Image'],
                    ['createdAt', 'Created At'],
                  ]}
                  fetch={backend.user.sellRequests(userId)}
              />
            </div>
            <div className="box">
              <h2 className="subtitle">Events</h2>
              <DataTable
                  columns={[
                    ['createdAt', 'At'],
                    ['type', 'Type'],
                    ['payload', 'Payload'],
                  ]}
                  fetch={backend.user.events(userId)}
              />
            </div>
            <div className="box">
              <h2 className="subtitle">User label updates</h2>
              {railsUser?.audits.map((audit: any) => (
                  <React.Fragment key={`audit-${audit.id}`}>
                    {['status'].map((key: any) => {
                      if (!audit.auditedChanges[key]) {
                        return false
                      }

                      return (
                          <div key={`audit-${audit.id}-${key}`}>
                            {_.startCase(key)} {ACTIONS[audit.action]}&nbsp;
                            from {getAuditedValue(key, audit.auditedChanges[key][0])}&nbsp;
                            to {getAuditedValue(key, audit.auditedChanges[key][1])} at&nbsp;
                            {formatDateTime(audit.createdAt)}
                          </div>
                      )
                    })}
                  </React.Fragment>
              ))}
            </div>
            <div className="box">
              <h2 className="subtitle">Logins</h2>
              {railsUser?.audits.map((audit: any) => (
                  <React.Fragment key={`audit-${audit.id}`}>
                    {['lastLoginAt'].map((key: any) => {
                      if (!audit.auditedChanges[key]) {
                        return false
                      }

                      return (
                          <div key={`audit-${audit.id}-${key}`}>
                            Logged in at: {getAuditedValue(key, audit.auditedChanges[key][1])}
                          </div>
                      )
                    })}
                  </React.Fragment>
              ))}
            </div>
            <div className="box">
              <h2 className="subtitle">App versions</h2>
              {railsUser?.audits.map((audit: any) => (
                  <React.Fragment key={`audit-${audit.id}`}>
                    {['lastAppVersion'].map((key: any) => {
                      if (!audit.auditedChanges[key]) {
                        return false
                      }

                      return (
                          <div key={`audit-${audit.id}-${key}`}>
                            App version: {getAuditedValue(key, audit.auditedChanges[key][1])} at&nbsp;
                            {formatDateTime(audit.createdAt)}
                          </div>
                      )
                    })}
                  </React.Fragment>
              ))}
            </div>
          </>
      ) : (
          <div>
            <progress className="progress is-small is-primary" max="100">
              15%
            </progress>
          </div>
      )}
    </>
  );
};

export default UserPage;
