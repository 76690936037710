import React, {FC, useEffect, useRef, useState} from "react";
import { Lot } from "../Backend/Models/Lot";
import * as backend from '../Backend';
import firebase from "firebase";
import {formatEpochTime} from "../Util";
import { DataTable } from "./DataTable";
import { User } from "../Backend/Models/User";
import _ from "lodash-es";

type IProps = {}

const AuctionRoom: FC<IProps> = () => {
  const [bidders, setBidders] = useState<any[]>([])
  const [spectators, setSpectators] = useState<any[]>([])
  const [activeLot, setActiveLot] = useState<Lot | null>(null)
  const [auctionRegistrations, setAuctionRegistrations] = useState<any[] | null>(null)
  const cachedUsers = useRef<User[]>([])

  useEffect(() => {
    backend
      .lot
      .upcoming()
      .then((upcomingLots) => setActiveLot(upcomingLots[0]))
  }, [])

  useEffect(() => {
    if (!activeLot) return

    let refresh = setInterval(() => {
      backend
        .lot
        .registrationsUnpaginated(activeLot.id)
        .then((data) => {
          setAuctionRegistrations(data)

          // const biddersData = data
          //   .filter((ar: any) => ar.account?.primary && ar.account?.canBid)
          //   .map((b: any) => ({
          //     ...b,
          //     user: b.users[0],
          //   }))
          // const spectatorsData = data
          //   .filter((ar: any) => !ar.account?.primary || !ar.account?.canBid)
          //   .map((s: any) => ({
          //     ...s,
          //     user: s.users[0],
          //   }))

          // setBidders(biddersData)
          // setSpectators(spectatorsData)
        })
    }, 5000)

    return () => {
      clearInterval(refresh)
    }
  }, [activeLot])

  useEffect(() => {
    if (!auctionRegistrations) return

    const presenceRef = firebase.database().ref('auction-room-presence')
    const onValueChange = async (snapshot: any) => {
      let realtimeDatabaseUsers: any[] = [];

      snapshot.forEach((child: any) => {
        realtimeDatabaseUsers.push(child)
      })

      const newUsers = realtimeDatabaseUsers.filter((u: any) => !cachedUsers.current.find((cu) => cu.id === u.key))
      let users: User[] = [];

      if(newUsers.length) {
        const chunkedUsers = _.chunk(newUsers, 20)

        for (const userBlock of chunkedUsers) {
          users = users.concat(await backend.user.allById(userBlock.map(u => u.key)))
        }
      }
      const bidders: any[] = [];
      const spectators: any[] = [];

      realtimeDatabaseUsers.forEach((pu) => {
        const user = cachedUsers.current.find(e => e.id === pu.key) || users.find(e => e.id === pu.key)
        const auctionRegistration = auctionRegistrations.find(ar => user!.accounts.find(a => a.primary && a.canBid)?.id === ar.account?.id)

        if (auctionRegistration) {
          bidders.push({
            joinedAt: pu.val(),
            user,
            auctionRegistration
          })
        } else {
          spectators.push({
            joinedAt: pu.val(),
            user,
          })
        }
      });

      cachedUsers.current = cachedUsers.current.concat(users)

      setBidders(bidders)
      setSpectators(spectators)
    }

    presenceRef.orderByKey().on('value', onValueChange)

    return () => {
      presenceRef.off('value', onValueChange);
    }
  }, [auctionRegistrations])

  if (!activeLot) {
    return <div>
      <progress className="progress is-small is-primary" max="100">15%</progress>
    </div>
  }

  if (!activeLot.auctionLive) {
    return <div>
      <h1>There's no active auction</h1>
    </div>
  }

  if (!auctionRegistrations || !bidders || !spectators) {
    return <div>
      <progress className="progress is-small is-primary" max="100">15%</progress>
    </div>
  }

  return <>
    <div className="box" style={{ backgroundColor: 'lightblue' }}>
      <h2 className="subtitle">LIVE AUCTION ROOM: {activeLot.title}</h2>
      <h2 className="subtitle">Bidders</h2>
      <table className="table is-fullwidth">
        <thead>
        <tr>
          <th>Joined At</th>
          <th>User Name</th>
          <th>Account Name</th>
          <th>Paddle</th>
          <th>Email</th>
        </tr>
        </thead>
        <tbody>
        {bidders.map((bidder) => <tr key={bidder.user.id}>
          <td>{formatEpochTime(bidder.joinedAt)}</td>
          <td>{bidder.user.firstName} {bidder.user.lastName}</td>
          <td>{bidder.auctionRegistration?.account?.name}</td>
          <td>{bidder.auctionRegistration?.paddle}</td>
          <td>{bidder.user.email}</td>
        </tr>)}
        </tbody>
      </table>
      <h2 className="subtitle">Spectators</h2>
      <table className="table is-fullwidth">
        <thead>
        <tr>
          <th>Joined At</th>
          <th>User Name</th>
          <th>Email</th>
        </tr>
        </thead>
        <tbody>
        {spectators.map((spectator) => <tr key={spectator.user.id}>
          <td>{formatEpochTime(spectator.joinedAt)}</td>
          <td>{spectator.user.firstName} {spectator.user.lastName}</td>
          <td>{spectator.user.email}</td>
        </tr>)}
        </tbody>
      </table>
    </div>
    <div className="box">
      <h2 className="subtitle">Enter Bidding Room Events</h2>
      <DataTable
        columns={[
          ['type', 'Type'],
          ['createdAt', 'At'],
          ['userName', 'User', ({ entity }: any) => `${entity.payload?.user?.firstName} ${entity.payload?.user?.lastName}`],
          ['accountName', 'Account', ({ entity }: any) => entity.payload?.account?.name],
        ]}
        fetch={backend.lot.biddingRoomEvents(activeLot.id)}
      />
    </div>
    <div className="box">
      <table className="table is-fullwidth">
        <thead>
        <tr>
          <th>Auction Mobility ID</th>
          <th>Account Name</th>
          <th>Paddle</th>
        </tr>
        </thead>
        <tbody>
        {auctionRegistrations!.map((ar) => <tr key={`${ar.id}-${ar.auctionMobilityId}`}>
          <td>{ar.auctionMobilityId}</td>
          <td>{ar.account?.name}</td>
          <td>{ar.paddle}</td>
        </tr>)}
        </tbody>
      </table>
    </div>
  </>
}

export default AuctionRoom
