import React, { useCallback, useEffect, useState } from 'react';
import * as O from 'fp-ts/lib/Option'
import * as backend from '../Backend';
import { User } from '../Backend/Models/User';
import { Column } from "../Components/DataTable";
import { DataTableControlled } from "../Components/DataTableControlled";
import { useDebounceCallback } from "@react-hook/debounce";
import ConfirmationModal from "../Components/ConfirmationModal";

const styleUserAcceptance = { cursor: 'pointer', color: '#CC0F35FF' }

const STATUSES: any = {
  'pending': 'Pending',
  'on_hold': 'On Hold',
  'rejected': 'Rejected',
  'accepted': 'Accepted',
}

const UserAcceptance = ({ user, onClick }: { user: User, onClick: any }) => {
  const styleWithColor = user.status === 'accepted' ? {color: '#6c6c6c'} : styleUserAcceptance
  const handleClick = user.status === 'accepted' ? () => {} : () => onClick(user)

  return (
      <p style={styleWithColor} onClick={handleClick}>
        Accept
      </p>
  )
}

const UsersPage: React.FC<{
  routeToUser: any,
  routeToUsers: any,
  page?: number,
  emailMatching?: string,
  phoneNumberMatching?: string,
  fullNameMatching?: string,
  canBid?: boolean,
  toast: any
}> = ({
  routeToUser,
  routeToUsers,
  page,
  emailMatching,
  canBid,
  phoneNumberMatching,
  fullNameMatching,
  toast
}) => {
  const [internalEmailStartsWith, setEmailStartsWith] = useState(emailMatching)
  const [internalPhoneNumberStartsWith, setPhoneNumberStartsWith] = useState(phoneNumberMatching)
  const [internalFullNameStartsWith, setFullNameStartsWith] = useState(fullNameMatching)
  const [forceReload, setForceReload] = useState<any>(Math.random())
  const [showRemoveBidders, setShowRemoveBidders] = useState(false)
  const [currentUser, setCurrentUser] = useState<any>(null)
  const [showAcceptConfirmation, setShowAcceptConfirmation] = useState(false)

  const onClickAccept = (user: User) => {
    setCurrentUser(user)
    setShowAcceptConfirmation(true)
  }

  const onCloseAcceptance = () => {
    setCurrentUser(null)
    setShowAcceptConfirmation(false)
  }

  async function view(user: User) {
    routeToUser(user)
    return O.none
  }

  function clear() {
    setEmailStartsWith('')
    setPhoneNumberStartsWith('')
    setFullNameStartsWith('')
    routeToUsers({})
  }

  const performSearch = useDebounceCallback(() => {
    return routeToUsers({
      page: page || 1,
      emailMatching: internalEmailStartsWith,
      phoneNumberMatching: internalPhoneNumberStartsWith,
      fullNameMatching: internalFullNameStartsWith,
      canBid
    })
  }, 200)

  const onAcceptance = async () => {
    await backend.user.accept(currentUser!)
    setForceReload(Math.random())
    onCloseAcceptance();
  }

  useEffect(() => {
    performSearch()
  }, [internalEmailStartsWith, internalPhoneNumberStartsWith, internalFullNameStartsWith]) // eslint-disable-line react-hooks/exhaustive-deps

  const columns: Column[] = [
    ['accept', '', ({ entity }: { entity: User }) => <UserAcceptance user={entity} onClick={onClickAccept} />],
    ['firstName', 'First Name'],
    ['lastName', 'Last Name'],
    ['email', 'Email'],
    ['createdAt', 'Created At'],
    ['status', 'Status', ({ entity }: { entity: User }) => STATUSES[entity.status]],
    ['phoneNumber', 'Phone', ({ entity }: { entity: User }) => entity.profile?.phoneNumber],
    ['referral', 'Referral', ({ entity }: { entity: User }) => entity.referralCode?.code]
  ]

  const actions = [
    { action: view, label: 'View' },
  ]

  const fetchPage = useCallback((page) => {
    return backend.user.all(page, {
      emailMatching: emailMatching || '',
      phoneNumberMatching: phoneNumberMatching || '',
      fullNameMatching: fullNameMatching || '',
      canBid: !!canBid
    })
  }, [emailMatching, phoneNumberMatching, fullNameMatching, canBid])

  const removeBidders = async () => {
    await backend.bidders._delete()
    toast({ type: 'good', message: 'Removed bidding accounts' })
    setShowRemoveBidders(false)
    setForceReload(Math.random())
  }

  const onPageChange = (page: number) => routeToUsers({
    page,
    emailMatching,
    phoneNumberMatching,
    fullNameMatching,
    canBid
  })

  const onChangeBiddingAccounts = () => {
    routeToUsers({ page: 1, canBid: !canBid, emailMatching, phoneNumberMatching, fullNameMatching })
  }

  return (
    <>
      <ConfirmationModal
        bodyText={'Are you sure you want to remove all bidders? This will prevent anyone from bidding. Do not do this during a live auction!'}
        confirmText="Remove Bidders"
        visible={showRemoveBidders}
        onConfirm={removeBidders}
        onCancel={() => setShowRemoveBidders(false)}
      />
      {currentUser && (
          <ConfirmationModal
              bodyText={`Would you like to accept ${currentUser.firstName} ${currentUser.lastName}? There's no undo.`}
              confirmText="Accept"
              visible={showAcceptConfirmation}
              onConfirm={onAcceptance}
              onCancel={onCloseAcceptance}
          />
      )}
      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li className="is-active"><span className="as_link as_link_padding">Users</span></li>
        </ul>
      </nav>
      <div className="box">
        <div className="columns">
          <div className="column">
            <input type="email"
                   className="input"
                   placeholder="Email..."
                   value={internalEmailStartsWith}
                   onChange={(e) => setEmailStartsWith(e.target.value)}
            />
          </div>

          <div className="column">
            <input type="phoneNumber"
              className="input"
              placeholder="Phone Number..."
              value={internalPhoneNumberStartsWith}
              onChange={(e) => setPhoneNumberStartsWith(e.target.value)}
            />
          </div>

          <div className="column">
            <input type="fullName"
              className="input"
              placeholder="Full Name..."
              value={internalFullNameStartsWith}
              onChange={(e) => setFullNameStartsWith(e.target.value)}
            />
          </div>

          <div className="column is-flex" style={{justifyContent: 'space-between'}}>
            <div style={{marginRight: '10px'}}>
              <label className="checkbox is-flex" style={{height: '100%'}}>
                <input type="checkbox" checked={canBid} onChange={onChangeBiddingAccounts}/>
                <span className="block is-flex"
                      style={{flexDirection: 'column', justifyContent: 'center', marginLeft: '5px'}}>With bidding accounts</span>
              </label>
            </div>
            <button className="button" onClick={clear}>Clear</button>
          </div>
        </div>
        <DataTableControlled columns={columns}
                             forceReload={forceReload}
                             fetch={fetchPage}
                             actions={actions}
                             page={page || 1}
                             onPageChange={onPageChange}
        />
        <div className="columns">
          <div className="column">
            <button className="button is-danger" onClick={() => setShowRemoveBidders(true)}>Remove all Bidders</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsersPage
