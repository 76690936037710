import * as React from 'react'
import { Dispatch } from 'react'
import * as cmd from 'elm-ts/lib/Cmd'
import * as backend from '../Backend';
import * as O from 'fp-ts/lib/Option'
import { isNone, none, Option, some } from 'fp-ts/lib/Option'
import { Location, push } from 'elm-ts/lib/Navigation'
import { Html } from 'elm-ts/lib/React'
import firebase, { User as FirebaseUser } from "firebase";
import { perform } from "elm-ts/lib/Task";
import fwLogo from '../Style/fairwarning.png';
import * as T from 'fp-ts/lib/Task'
import { delay, Task } from 'fp-ts/lib/Task'
import {
  AppRoute,
  AppRoutes,
  auctionUrl,
  referralCodesUrl,
  featuresUrl,
  parkingLotsUrl,
  eventsUrl,
  formatRoute,
  lotDetailUrl,
  lotsUrl,
  parseUrl,
  signInUrl,
  userDetailUrl,
  usersUrl,
  massNotificationsUrl,
  auctionAlertsUrl,
} from '../Routes'
import { Match } from "fp-ts-routing";
import { pipe } from "fp-ts/lib/pipeable";
import { User } from "../Backend/Models/User";
import UsersPage from "./UsersPage";
import UserPage from "./UserPage";
import LotsPage from "./LotsPage";
import { SignInPage } from "./SignInPage";
import { SignUpPage } from "./SignUpPage";
import LotPage from "./LotPage";
import EventsPage from "./EventsPage";
import MassNotificationsPage from "./MassNotificationsPage";
import AuctionPage from "./AuctionPage";
import AuctionRoomAlerts from '../Components/AuctionRoomAlerts';
import Reports from '../Components/Reports';
import SettingsPage from "./SettingsPage";
import ReferralCodesPage from "./ReferralCodesPage";
import FeaturesPage from "./FeaturesPage";
import ParkingLotsPage from "./ParkingLotsPage";
import VipPage from "./VipPage";

// --- Flags
export type Flags = AppRoute

const defaultRoute = AppRoutes.Users({ page: 1 })

export const flags: Flags = defaultRoute

export type Toast = { message: string, type: string }

// --- Model
export type Model = { route: AppRoute, user: Option<FirebaseUser>, toast: Option<Toast> }

export function locationToMsg(location: Location): Msg {
  const appRoute = parseUrl(location)
  return { type: 'HandleRoute', appRoute } as Msg
}


function configureBackend(user: any, mfaToken: string) {
  backend.configure(
    () => user.getIdToken(),
    mfaToken
  );
}

async function checkAdmin(user: FirebaseUser | null): Promise<boolean> {
  let admin = false;
  if (user) {
    try {
      const res = await backend.user.me()
      admin = res.admin
    } catch {
      admin = false
    }
  }

  return admin
}

type AuthCheck = { user: FirebaseUser | null, admin: boolean }

function checkAuth(): T.Task<AuthCheck> {
  return () =>
    new Promise(resolve => {
      let unsubscribe: any;
      unsubscribe = firebase.auth().onAuthStateChanged(async (user: any) => {
        const token = localStorage.getItem("mfaToken")
        if (user && token) {
          configureBackend(user, token)
          const admin = await checkAdmin(user);
          resolve({ user, admin });
        } else {
          resolve({ user: null, admin: false })
        }
        unsubscribe();
      })
    })
}

function initialRoute(location: Location): (ac: AuthCheck) => Msg {
  return ({ user, admin }) => {
    if (user) {
      if (admin) {
        return { type: 'SetUser', user: user, url: parseUrl(location) }
      } else {
        signOut()
        return { type: 'Push', routeMatch: [signInUrl, {}], toast: { type: 'good', message: 'Signed out' } }
      }
    } else {
      return { type: 'Push', routeMatch: [signInUrl, {}] }
    }
  }
}

export function init(_: Flags): (location: Location) => [Model, cmd.Cmd<Msg>] {
  return location => [{
    route: AppRoutes.Loading({}),
    user: O.none,
    toast: O.none
  }, perform(initialRoute(location))(checkAuth())]
}

type MatchRoute<A extends object> = Match<A>
type RouteMatcher<A extends object> = [MatchRoute<A>, A]
// --- Messages
export type Msg =
  { type: 'HandleRoute', appRoute: AppRoute }
  | { type: 'SetUser'; user: FirebaseUser; url: AppRoute }
  | { type: 'Push', routeMatch: RouteMatcher<any>, toast?: Toast }
  | { type: 'PerformSignOut' }
  | { type: 'AfterSignOut' }
  | { type: 'Toast', toast: Toast }
  | { type: 'ClearToast' }

const goToSignIn = (): cmd.Cmd<Msg> => (push(formatRoute(signInUrl)({})))

// --- Update
export function update(msg: Msg, model: Model): [Model, cmd.Cmd<Msg>] {
  switch (msg.type) {
    case 'HandleRoute':
      const onCmd: cmd.Cmd<Msg> = AppRoutes.match(msg.appRoute, {
        SignOut: () => goToSignIn(),
        default: () => cmd.none
      })
      return [{ ...model, route: msg.appRoute }, onCmd]

    case 'Push':
      const firstMatch = msg.routeMatch[0]
      const cmds: any = [push(formatRoute(firstMatch)(msg.routeMatch[1]))]
      if (msg.toast) {
        cmds.push(perform(_ => ({ type: 'Toast', toast: msg.toast }))(() => Promise.resolve(true)))
      }
      return [model, cmd.batch(cmds)]

    case 'PerformSignOut':
      const afterSignOut: Msg = ({ type: 'AfterSignOut' })
      return [{ ...model, user: none }, perform(_ => afterSignOut)(signOut)]

    case 'AfterSignOut':
      return [model, goToSignIn()]

    case 'SetUser':
      const handleMsg: Msg = { type: 'HandleRoute', appRoute: msg.url }
      const toastMsg: Msg = { type: 'Toast', toast: { type: 'good', message: 'Signed in.' } }
      return [{ ...model, user: some(msg.user) }, cmd.batch<any>([
        perform(_ => handleMsg)(() => Promise.resolve(true)),
        perform(_ => toastMsg)(() => Promise.resolve(true)),
      ])]

    case 'Toast':
      const clearMsg: Msg = { type: 'ClearToast' }
      const task: Task<boolean> = delay(3000)(() => Promise.resolve(true))
      return [{ ...model, toast: O.some(msg.toast) }, perform(_ => clearMsg)(task)]

    case 'ClearToast':
      return [{ ...model, toast: O.none }, cmd.none]
  }
}

async function signOut(): Promise<any> {
  await backend.user.signOut()
  localStorage.removeItem("mfaToken")
  return firebase.auth().signOut()
}

function body(dispatch: Dispatch<Msg>, model: Model): any {
  if (AppRoutes.is.Loading(model.route)) {
    return LoadingRoute(dispatch);
  }

  if (isNone(model.user)) {
    return AppRoutes.match(model.route, {
      Unauthorized: () => (UnauthorizedRoute(dispatch)),
      SignIn: () => SignInRoute(dispatch),
      SignUp: () => {
        return SignUpRoute(dispatch)
      },
      default: () => NotFoundRoute(dispatch)
    })
  } else {
    return AppRoutes.match(model.route, {
      Users: (params) => (UsersRoute(params)(dispatch)),
      UserDetail: ({ userId }) => (UserRoute(userId)(dispatch)),
      Lots: () => LotsRoute(dispatch),
      LotDetail: ({ lotId }) => (LotRoute(lotId)(dispatch)),
      Auction: () => (AuctionRoute(dispatch)),
      ReferralCodes: (params) => (ReferralCodesRoute(params)(dispatch)),
      Features: () => (FeaturesRoute(dispatch)),
      ParkingLots: () => (ParkingLotsRoute(dispatch)),
      Settings: () => (SettingsRoute(dispatch)),
      AuctionAlerts: () => (AuctionAlertsRoute(dispatch)),
      Events: () => (EventsRoute(dispatch)),
      MassNotifications: () => (MassNotificationsRoute(dispatch)),
      Reports: () => (ReportsRoute(dispatch)),
      Vip: () => (VipRoute(dispatch)),
      default: () => NotFoundRoute(dispatch)
    })
  }
}

// --- View
export function view(model: Model): Html<Msg> {
  return dispatch => (
    <div>
      {NavBar(model)(dispatch)}
      <div style={{ margin: '40px' }}>
        {body(dispatch, model)}
      </div>
      {pipe(model.toast, O.fold(
        () => <div/>,
        (toast) =>
          <div id="toast" className={`is-visible notification ${toast.type === 'good' ? 'is-primary' : 'is-danger'}`}>
            {toast.message}
          </div>
      ))}
    </div>
  )
}


let bannerColor: string;
if (process.env.REACT_APP_ENV_BANNER === 'production') {
  bannerColor = 'is-danger'
} else if (process.env.REACT_APP_ENV_BANNER === 'staging') {
  bannerColor = 'is-warning'
} else {
  bannerColor = 'is-success'
}

const NavBar = (model: Model): (Html<Msg> | any) => {
  const url = window.location && window.location['href'];
  const shouldDisplayNavBar = !url.includes('#/vip');

  return (dispatch: any) => (
    <nav className="navbar has-shadow">
      <div className="navbar-brand">
        <div className="navbar-item is-uppercase has-text-weight-bold" style={{ background: 'black', color: 'white' }}>
          <img src={fwLogo} alt="logo"/>
        </div>
        <div
          className={`navbar-item is-uppercase notification is-light ${bannerColor}`}>
          {process.env.REACT_APP_ENV_BANNER || 'development'} | v{ process.env.REACT_APP_VERSION ||'1.0.0' }
        </div>
      </div>
      {O.isSome(model.user) && shouldDisplayNavBar ?
        <div className="navbar-menu">
          <div className="navbar-start">
            <span style={{ cursor: 'pointer' }} onClick={() => dispatch({type: 'Push', routeMatch: [usersUrl, {page: 1}]})}
               className="navbar-item">
              Users
            </span>
            <span style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: "Push", routeMatch: [lotsUrl, {}] })}
               className="navbar-item">
              Lots
            </span>
            <span style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: "Push", routeMatch: [auctionUrl, {}] })}
               className="navbar-item">
              Auction Room
            </span>
            <span style={{ cursor: 'pointer' }} onClick={() => dispatch({type: 'Push', routeMatch: [referralCodesUrl, {page: 1}]})}
               className="navbar-item">
              Invite Codes
            </span>
            <span style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: "Push", routeMatch: [featuresUrl, {}] })}
               className="navbar-item">
              Features
            </span>
            <span style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: "Push", routeMatch: [parkingLotsUrl, {}] })}
               className="navbar-item">
              Parking Lots
            </span>
            <span style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: "Push", routeMatch: [eventsUrl, {}] })}
               className="navbar-item">
              Events
            </span>
            <span style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: "Push", routeMatch: [massNotificationsUrl, {}] })}
               className="navbar-item">
              Mass Notifications
            </span>
            <span style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: "Push", routeMatch: [auctionAlertsUrl, {}] })}
               className="navbar-item">
              Auction Alerts
            </span>
          </div>
          <div className="navbar-end">
            <span style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: "PerformSignOut" })}
               className="navbar-item">
            Sign Out
            </span>
          </div>
        </div> : null
      }
    </nav>
  )
}
const LoadingRoute: Html<Msg> = dispatch => (
  <div/>
)

const UnauthorizedRoute: Html<Msg> = dispatch => (
  <div>
    <div>
      Unauthorized
    </div>
    <div>
      <span style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: 'Push', routeMatch: [signInUrl, {}] })}>Sign In</span>
    </div>
  </div>
)

const MassNotificationsRoute: Html<Msg> = dispatch => {
  return <div className="container">
    <MassNotificationsPage toast={(toast) => runToast(dispatch)(toast)}/>
  </div>
}

const UsersRoute = ({
  page,
  emailMatching,
  phoneNumberMatching,
  fullNameMatching,
  canBid
}: { page?: number, emailMatching?: string, phoneNumberMatching?: string, fullNameMatching?: string, canBid?: boolean }): Html<Msg> =>
  dispatch => {
    function routeToUser(user: User) {
      dispatch({ type: 'Push', routeMatch: [userDetailUrl, { userId: user.id }] })
    }

    function routeToUsers({
      page,
      emailMatching,
      phoneNumberMatching,
      fullNameMatching,
      canBid
    }: { page?: number, emailMatching?: string, phoneNumberMatching?: string, fullNameMatching?: string, canBid?: boolean }) {
      dispatch({ type: 'Push', routeMatch: [usersUrl, { page: page, emailMatching, phoneNumberMatching, fullNameMatching, canBid }] })
    }

    return (
      <div className="container">
        <UsersPage toast={(toast: any) => runToast(dispatch)(toast)} routeToUser={routeToUser}
          routeToUsers={routeToUsers}
          page={page}
          emailMatching={emailMatching}
          phoneNumberMatching={phoneNumberMatching}
          fullNameMatching={fullNameMatching}
          canBid={canBid}
        />
      </div>
    )
  }

function runToast(dispatch: any) {
  return (toast: Toast) => dispatch({ type: 'Toast', toast })
}

const UserRoute = (userId: string): Html<Msg> => {
  return dispatch => {
    return <div className="container">
      <UserPage toast={(toast) => runToast(dispatch)(toast)} userId={userId}/>
    </div>
  }
}

const AuctionRoute: Html<Msg> = dispatch => {
  return <div className="container">
    <AuctionPage toast={(toast) => runToast(dispatch)(toast)}/>
  </div>
}

const ReferralCodesRoute = (params: { page?: number, emailStartsWith?: string, canBid?: boolean }): Html<Msg> =>
    dispatch => {

  return (
    <div className="container">
      <ReferralCodesPage {...params} toast={(toast) => runToast(dispatch)(toast)}/>
    </div>
  )
}

const FeaturesRoute: Html<Msg> = dispatch => {
  return (
      <div className="container">
        <FeaturesPage toast={(toast) => runToast(dispatch)(toast)}/>
      </div>
  )
}

const ParkingLotsRoute: Html<Msg> = dispatch => {
  return (
    <div className="container">
      <ParkingLotsPage toast={(toast) => runToast(dispatch)(toast)}  />
    </div>
  )
}

const SettingsRoute: Html<Msg> = dispatch => {
  return <div className="container">
    <SettingsPage toast={(toast) => runToast(dispatch)(toast)}/>
  </div>
}


const AuctionAlertsRoute: Html<Msg> = dispatch => {
  return <div className="container">
    <AuctionRoomAlerts/>
  </div>
}

const ReportsRoute: Html<Msg> = dispatch => {
  return <div className="container">
    <Reports toast={(toast) => runToast(dispatch)(toast)}/>
  </div>
}

const VipRoute: Html<Msg> = dispatch => {
  return (
    <div className="container">
      <VipPage />
    </div>
  )
}

const LotRoute = (lotId: string): Html<Msg> => {
  return dispatch => (
    <div className="container">
      <LotPage toast={(toast) => runToast(dispatch)(toast)} lotId={lotId}/>
    </div>
  )
}


const LotsRoute: Html<Msg> = dispatch => {
  function routeToLot(lot: any) {
    dispatch({ type: 'Push', routeMatch: [lotDetailUrl, { lotId: lot.id }] })
  }

  return <div className="container">
    <LotsPage toast={(toast) => runToast(dispatch)(toast)} routeToLot={routeToLot}/>
  </div>
}

const EventsRoute: Html<Msg> = dispatch => {
  return <div className="container">
    <EventsPage toast={(toast) => runToast(dispatch)(toast)}/>
  </div>
}


const SignInRoute: Html<Msg> = dispatch => (
  <div className="container">
    <SignInPage toast={(toast: Toast) => runToast(dispatch)(toast)} onSignIn={async (user: any, mfaToken: string) => {
      localStorage.setItem("mfaToken", mfaToken)
      configureBackend(user, mfaToken);
      const admin = await checkAdmin(user);

      if (admin) {
        dispatch({ type: 'SetUser', user: user, url: AppRoutes.Users({ page: 1 }) });
      } else {
        await signOut()
        runToast(dispatch)({ type: 'bad', message: 'Unauthorized' })
        dispatch({ type: 'Push', routeMatch: [signInUrl, {}] });
      }
    }}/>
  </div>
)

const NotFoundRoute: Html<Msg> = dispatch => (
  <div className="container">
    Not Found
  </div>
)

const SignUpRoute: Html<Msg> = dispatch => (
  <div className="container">
    <SignUpPage onSignUp={() => dispatch({ type: 'Push', routeMatch: [signInUrl, {}] })}/>
  </div>
)
