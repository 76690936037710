import React, { useState } from "react";
import firebase from 'firebase'
import { useForm } from "react-hook-form";
import * as backend from '../Backend';

export function SignInPage(props: any) {
  const { register, handleSubmit, errors, reset } = useForm()
  const { register: codeRegister, handleSubmit: handleCodeSubmit, reset: resetCode } = useForm()

  const [user, setUser] = useState<firebase.User | null>(null)

  const onSubmit = async (data: any) => {
    firebase.auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => firebase.auth().signInWithEmailAndPassword(data.email, data.password))
      .then((creds: any) => {
        reset()
        requestPhone(creds.user)
        setUser(creds.user)
        creds.user!.getIdToken().then((token: any) => console.log("token", token))
        props.toast({ type: 'good', message: 'Sent verification code to phone.' })
      })
      .catch(() => {
        props.toast({ type: 'bad', message: 'Invalid username/password' })
      })
  }

  const requestPhone = (user: any) => {
    backend
      .user
      .mfaRequest(user)
      .catch((err) => console.error(err))
  }

  const onCodeSubmit = (data: any) => {
    backend
      .user
      .confirmMfa(user!, data)
      .then((result) => {
        console.log('result => ', result)
        resetCode()
        if (!!result.data.id) {
          props.onSignIn(user!, result.data?.attributes?.token)
          props.toast({ type: 'good', message: 'Successfully signed in.' })
        } else {
          props.toast({ type: 'bad', message: 'Incorrect code' })
        }
      })
      .catch(() => props.toast({ type: 'bad', message: 'Incorrect code' }))
  }

  return (
    <div className="columns">
      <div className="column"/>
      <div className="column is-half">
        <div className="panel">
          <div className="panel-heading">
            Sign In
          </div>
          <div className="panel-block">
            <div className="container">
              {user ?
                <form onSubmit={handleCodeSubmit(onCodeSubmit)}>
                  <div className="field">
                    <label htmlFor="code" className="label">Code</label>
                    <div className="control">
                      <input className="input" name="code" ref={codeRegister({ required: true })}/>
                      {errors.code && <p className="help is-danger">Code incorrect</p>}
                    </div>
                  </div>

                  <button className="button is-success" type="submit">Confirm Code</button>
                </form>
                :
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="field">
                    <label htmlFor="email" className="label">Email</label>
                    <div className="control has-icons-left">
                      <input className="input" name="email" ref={register({ required: true })}/>
                      <span className="icon is-small is-left">
                  <i className="fas fa-envelope"/>
                </span>
                      {errors.email && <p className="help is-danger">Email required</p>}
                    </div>
                  </div>

                  <div className="field">
                    <label htmlFor="password" className="label">Password</label>
                    <div className="control has-icons-left">
                      <input className="input" name="password" type="password" ref={register({ required: true })}/>
                      <span className="icon is-small is-left">
                  <i className="fas fa-lock"/>
                </span>
                      {errors.password && <p className="help is-danger">Password required</p>}
                    </div>
                  </div>

                  <button className="button is-success" type="submit">Sign In</button>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="column"/>
    </div>
  )
}

