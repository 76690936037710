import { FC } from "react"
import React from "react"

const ConfirmationModal: FC<{ bodyText: string, confirmText: string, cancelText?: string, visible: boolean, onCancel: () => void, onConfirm: () => void }> = ({ bodyText, confirmText, cancelText = "Cancel", visible, onConfirm, onCancel }) => {
    return <div className={`modal ${visible ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={onCancel} />
        <div className="modal-content">
            <div className="box">
                <div>{bodyText}</div>
                <div className="buttons is-right">
                    <button className="button is-danger is-light" onClick={onCancel}>{cancelText}</button>
                    <button className="button is-primary" onClick={onConfirm}>{confirmText}</button>
                </div>
            </div>
        </div>
        <button className="modal-close is-large" onClick={onCancel} />
    </div>
}

export default ConfirmationModal